import React from "react"
import { withTranslation } from "react-i18next"
import "./statsAbout.scss"

function StatsAbout(props) {
  const { t } = props
  return (
    <div className="stats-about">
      <div id="statsAbout" className="stats stats--blue stats--overlay">
        <div className="container">
          <div className="stats__wrapper">
            <div className="stats__item stats__item--4">
              <div className="stats__content stats__content--vertically">
                <p className="stats__number stats__number--inline stats__number--large">
                  2+
                </p>
                <h3 className="stats__heading stats__heading--inline">
                  {t("src.pages.companyPage.operation")}
                </h3>
              </div>
            </div>
            <div className="stats__item stats__item--4">
              <div className="stats__content stats__content--vertically">
                <p className="stats__number stats__number--inline stats__number--large">
                  300+
                </p>
                <h3 className="stats__heading stats__heading--inline">
                  {t("src.pages.companyPage.clients")}
                </h3>
              </div>
            </div>
            <div className="stats__item stats__item--4">
              <div className="stats__content stats__content--vertically">
                <p className="stats__number stats__number--inline stats__number--large">
                  100+
                </p>
                <h3 className="stats__heading stats__heading--inline">
                  {t("src.pages.companyPage.employees")}
                </h3>
              </div>
            </div>
            <div className="stats__item stats__item--4">
              <div className="stats__content stats__content--vertically">
                <p className="stats__number stats__number--inline stats__number--large">
                  3
                </p>
                <h3 className="stats__heading stats__heading--inline">
                  {t("src.pages.companyPage.offices")}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(StatsAbout)
