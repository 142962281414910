import React, { Component } from 'react'
import CompanyPageTitle from './companyHomePage/companyPageTitle/companyPageTitle'
import StatsAbout from './companyHomePage/statsAbout/statsAbout'
import MissionVision from './companyHomePage/missionVision/missionVision'
import ClientsServices from './companyHomePage/servicesClients/clientsServices'
import Technology from './companyHomePage/technology/technology'
import ServiceCta from './companyHomePage/serviceCta/serviceCta'

class CompanyPage extends Component {
  render () {
    return (
      <div style={{ overflowX: 'hidden' }}>
        <CompanyPageTitle />
        <StatsAbout />
        <MissionVision />
        <ClientsServices />
        <Technology />
        <ServiceCta />
      </div>
    )
  }
}

export default CompanyPage
