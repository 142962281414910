import React from "react"
import { withTranslation } from "react-i18next"
import "./missionVision.scss"
// import { dataCompanyMissionVision } from "../../../../data/dataCompany"
import img from "../../../../assets/img/media/company/about/bg-about-map.jpg"

function MissionVision(props) {
  const { t } = props
  return (
    <div className="company-mission">
      <div id="map" className="map-scroll">
        <img src={img} alt="img"></img>
        <div className="container mission-vision">
          <div className="grid grid--column grid--center">
            <h2 className="title title--black title--medium title--uppercase">
              {t("src.pages.companyPage.visionAndMission")}
            </h2>
            <p className="text text--w-large text--s-small">
              {t("src.pages.companyPage.visionAndMissionContent")}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(MissionVision)
