import React from "react"
import { withTranslation } from "react-i18next"
import { Link } from "gatsby"
import { ChevronRight } from "react-feather"
import blockChainSvg from "../../../../assets/img/media/company/about/blockchain-technology.svg"
import AISvg from "../../../../assets/img/media/company/about/artificial-intelligence.svg"
import augmentedRealitySvg from "../../../../assets/img/media/company/about/VideoConferenceIcon.svg"
import IoTSvg from "../../../../assets/img/media/company/about/internet-of-things.svg"
import pageSvg from "../../../../assets/img/media/company/about/AppIcon.svg"
import "./technology.scss"

function Technology(props) {
  const { t } = props
  return (
    <div className="company-technology">
      <section className="enterprise-core">
        <div id="particles" />
        <div className="divider-t-50">
          <div className="container">
            <div className="grid grid--column grid--center">
              <h2 className="title title--black title--medium title--uppercase">
                {t("src.pages.companyPage.technology.title")}
              </h2>
            </div>
          </div>
        </div>
        <div className="hexa-container">
          <div className="item item-top">
            <svg className="hex hex-outer" viewBox="0 0 232 203">
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g
                  transform="translate(1.000000, 1.000000)"
                  stroke="#AAC7ED"
                  fill="white"
                >
                  <polygon points="230 100.5 172.5 201 57.5 201 0 100.5 57.5 0 172.5 0" />
                  <path d="M114,233.538767 L114,201" />
                </g>
              </g>
            </svg>
            <svg className="hex hex-inner">
              <use xlinkHref="#hexagon" />
            </svg>
            <figure className="hex-media">
              <img src={blockChainSvg} alt="Blockchain" />
              <figcaption>
                <h3>
                  <Link to="/" className="tech-text">
                    {t("src.pages.companyPage.technology.blockchain.title")}
                  </Link>
                </h3>
              </figcaption>
            </figure>
            <ul className="item-list item-list-top">
              {t("src.pages.companyPage.technology.blockchain.data").map(
                (content, index) => {
                  return (
                    <li key={index} style={{ fontFamily: "Open Sans" }}>
                      {content}
                    </li>
                  )
                }
              )}
            </ul>
          </div>
          <div className="item item-right-top">
            <svg className="hex hex-outer" viewBox="0 0 232 203">
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g
                  transform="translate(1.000000, 1.000000)"
                  stroke="#AAC7ED"
                  fill="white"
                >
                  <polygon points="230 100.5 172.5 201 57.5 201 0 100.5 57.5 0 172.5 0" />
                  <path d="M6,168.5 L30.5,153.5 L6,168.5 Z" />
                </g>
              </g>
            </svg>
            <svg className="hex hex-inner">
              <use xlinkHref="#hexagon" />
            </svg>
            <figure className="hex-media">
              <img src={augmentedRealitySvg} alt="XR" />
              <figcaption>
                <h3>
                  <Link to="/" className="tech-text">
                    {t("src.pages.companyPage.technology.XR.title")}
                  </Link>
                </h3>
              </figcaption>
            </figure>
            <ul className="item-list item-list-top-right">
              {t("src.pages.companyPage.technology.XR.data").map(
                (content, index) => {
                  return (
                    <li key={index} style={{ fontFamily: "Open Sans" }}>
                      {content}
                    </li>
                  )
                }
              )}
            </ul>
          </div>
          <div className="item item-right-bottom">
            <svg className="hex hex-outer" viewBox="0 0 232 203">
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g
                  transform="translate(1.000000, 1.000000)"
                  stroke="#AAC7ED"
                  fill="white"
                >
                  <polygon points="230 100.5 172.5 201 57.5 201 0 100.5 57.5 0 172.5 0" />
                  <path
                    d="M26.5,52.5 L3,38"
                    transform="translate(15.000000, 45.500000) scale(-1, -1) translate(-15.000000, -45.500000)"
                  />
                </g>
              </g>
            </svg>
            <svg className="hex hex-inner">
              <use xlinkHref="#hexagon" />
            </svg>
            <figure className="hex-media">
              <img src={pageSvg} alt="UX" />
              <figcaption>
                <h3>
                  <Link to="/" className="tech-text">
                    {t("src.pages.companyPage.technology.UX.title")}
                  </Link>
                </h3>
              </figcaption>
            </figure>
            <ul className="item-list item-list-bottom-right">
              {t("src.pages.companyPage.technology.UX.data").map(
                (content, index) => {
                  return (
                    <li key={index} style={{ fontFamily: "Open Sans" }}>
                      {content}
                    </li>
                  )
                }
              )}
            </ul>
          </div>
          <div className="item item-left-bottom">
            <svg className="hex hex-outer" viewBox="0 0 232 203">
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g
                  transform="translate(1.000000, 1.000000)"
                  stroke="#AAC7ED"
                  fill="white"
                >
                  <polygon points="230 100.5 172.5 201 57.5 201 0 100.5 57.5 0 172.5 0" />
                  <path
                    d="M226.5,52.5 L202.5,38"
                    transform="translate(214.500000, 45.500000) scale(1, -1) translate(-214.500000, -45.500000) "
                  />
                </g>
              </g>
            </svg>
            <svg className="hex hex-inner">
              <use xlinkHref="#hexagon" />
            </svg>
            <figure className="hex-media">
              <img src={AISvg} alt="AI" />
              <figcaption>
                <h3>
                  <Link to="/" className="tech-text">
                    {t("src.pages.companyPage.technology.AI.title")}
                  </Link>
                </h3>
              </figcaption>
            </figure>
            <ul className="item-list item-list-bottom-left">
              {t("src.pages.companyPage.technology.AI.data").map(
                (content, index) => {
                  return (
                    <li key={index} style={{ fontFamily: "Open Sans" }}>
                      {content}
                    </li>
                  )
                }
              )}
            </ul>
          </div>
          <div className="item item-left-top">
            <svg className="hex hex-outer" viewBox="0 0 232 203">
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g
                  transform="translate(1.000000, 1.000000)"
                  stroke="#AAC7ED"
                  fill="white"
                >
                  <polygon points="230 100.5 172.5 201 57.5 201 0 100.5 57.5 0 172.5 0" />
                  <path d="M224.5,168.5 L199.5,153.5" />
                </g>
              </g>
            </svg>
            <svg className="hex hex-inner">
              <use xlinkHref="#hexagon" />
            </svg>
            <figure className="hex-media">
              <img src={IoTSvg} alt="IoT" />
              <figcaption>
                <h3>
                  <Link to="/" className="tech-text">
                    {t("src.pages.companyPage.technology.IoT.title")}
                  </Link>
                </h3>
              </figcaption>
            </figure>
            <ul className="item-list item-list-bottom-left">
              {t("src.pages.companyPage.technology.IoT.data").map(
                (content, index) => {
                  return (
                    <li key={index} style={{ fontFamily: "Open Sans" }}>
                      {content}
                    </li>
                  )
                }
              )}
            </ul>
          </div>
          <div className="item item-center">
            <svg className="hex hex-outer" viewBox="0 0 232 203">
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g
                  transform="translate(1.000000, 1.000000)"
                  stroke="#AAC7ED"
                  fill="rgb(20, 118, 242)"
                >
                  <polygon points="230 100.5 172.5 201 57.5 201 0 100.5 57.5 0 172.5 0" />
                  {/* <path d='M224.5,168.5 L199.5,153.5' /> */}
                </g>
              </g>
            </svg>
            <svg className="hex">
              <use xlinkHref="#hexagon" />
            </svg>
            <figure className="hex-media hex-media-center">
              <figcaption>
                <h3 style={{ fontFamily: "Open Sans" }}>
                  {t("src.pages.companyPage.technology.enterprise.title")}
                </h3>
                <p style={{ fontFamily: "Open Sans", padding:'.7rem' }}>
                  {t("src.pages.companyPage.technology.enterprise.content")}
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      {/* technology mobile */}
      <section className="enterprise-core-mob">
        <div id="particles" />
        <div className="divider-t-50">
          <div className="container">
            <div className="grid grid--column grid--center">
              <h2 className="title title--black title--medium title--uppercase">
                {t("src.pages.companyPage.technology.title")}
              </h2>
            </div>
          </div>
        </div>
        <div className="tech-container">
          <div className="tech-item main">
            <svg
              className="tech-triangle up"
              xmlns="http://www.w3.org/2000/svg"
              width={276}
              height={40}
              viewBox="0 0 276 40"
            >
              <polygon
                fill="#1476F2"
                fillRule="evenodd"
                points="276 40 0 40 134.979 0"
              />
            </svg>
            <input type="checkbox" className="read-more-state" id="post-0" />
            <div className="read-more-wrap">
              <h3>{t("src.pages.companyPage.technology.enterprise.title")}</h3>
              <p>{t("src.pages.companyPage.technology.enterprise.content")}</p>
            </div>
            <svg
              className="tech-triangle"
              xmlns="http://www.w3.org/2000/svg"
              width={276}
              height={40}
              viewBox="0 0 276 40"
            >
              <polygon
                fill="#1476F2"
                fillRule="evenodd"
                points="276 0 134.979 40 0 0"
              />
            </svg>
            <label htmlFor="post-0" className="read-more-trigger main" />
            <div className="bg-cube" />
          </div>
          <div className="tech-item">
            <figure className="hex-media">
              <img src={blockChainSvg} alt="Blockchain" />
              <figcaption>
                <h3>
                  <Link to="/">
                    {t("src.pages.companyPage.technology.blockchain.title")}
                  </Link>
                </h3>
              </figcaption>
            </figure>
            <input type="checkbox" className="read-more-state" id="post-1" />
            <ul className="item-list read-more-wrap">
              {t("src.pages.companyPage.technology.blockchain.data").map(
                (content, index) => {
                  return <li key={index}>{content}</li>
                }
              )}
              <li className="learnmore">
                <Link to="/">learn more</Link>
                <ChevronRight className="rightArr" href="#/" size="20" />
              </li>
            </ul>
            <svg
              className="tech-triangle"
              xmlns="http://www.w3.org/2000/svg"
              width={276}
              height={42}
              viewBox="0 0 276 42"
            >
              <path
                fill="#FFF"
                fillRule="evenodd"
                stroke="#AAC7ED"
                d="M276 1L138 41 0 1"
              />
            </svg>
            <label htmlFor="post-1" className="read-more-trigger" />
          </div>
          <div className="tech-item">
            <figure className="hex-media">
              <img src={augmentedRealitySvg} alt="XR" />
              <figcaption>
                <h3>
                  <Link to="/">
                    {t("src.pages.companyPage.technology.XR.title")}
                  </Link>
                </h3>
              </figcaption>
            </figure>
            <input type="checkbox" className="read-more-state" id="post-2" />
            <ul className="item-list read-more-wrap">
              {t("src.pages.companyPage.technology.XR.data").map(
                (content, index) => {
                  return <li key={index}>{content}</li>
                }
              )}
              <li className="learnmore">
                <Link to="/">learn more</Link>
                <ChevronRight className="rightArr" href="#/" size="20" />
              </li>
            </ul>
            <svg
              className="tech-triangle"
              xmlns="http://www.w3.org/2000/svg"
              width={276}
              height={42}
              viewBox="0 0 276 42"
            >
              <path
                fill="#FFF"
                fillRule="evenodd"
                stroke="#AAC7ED"
                d="M276 1L138 41 0 1"
              />
            </svg>
            <label htmlFor="post-2" className="read-more-trigger" />
          </div>
          <div className="tech-item">
            <figure className="hex-media">
              <img src={pageSvg} alt="UX" />
              <figcaption>
                <h3>
                  <Link to="/">
                    {t("src.pages.companyPage.technology.UX.title")}
                  </Link>
                </h3>
              </figcaption>
            </figure>
            <input type="checkbox" className="read-more-state" id="post-3" />
            <ul className="item-list read-more-wrap">
              {t("src.pages.companyPage.technology.UX.data").map(
                (content, index) => {
                  return <li key={index}>{content}</li>
                }
              )}
              <li className="learnmore">
                <Link to="/">learn more</Link>
                <ChevronRight className="rightArr" href="#/" size="20" />
              </li>
            </ul>
            <svg
              className="tech-triangle"
              xmlns="http://www.w3.org/2000/svg"
              width={276}
              height={42}
              viewBox="0 0 276 42"
            >
              <path
                fill="#FFF"
                fillRule="evenodd"
                stroke="#AAC7ED"
                d="M276 1L138 41 0 1"
              />
            </svg>
            <label htmlFor="post-3" className="read-more-trigger" />
          </div>
          <div className="tech-item">
            <figure className="hex-media">
              <img src={AISvg} alt="AI" />
              <figcaption>
                <h3>
                  <Link to="/">
                    {t("src.pages.companyPage.technology.AI.title")}
                  </Link>
                </h3>
              </figcaption>
            </figure>
            <input type="checkbox" className="read-more-state" id="post-4" />
            <ul className="item-list read-more-wrap">
              {t("src.pages.companyPage.technology.AI.data").map(
                (content, index) => {
                  return <li key={index}>{content}</li>
                }
              )}
              <li className="learnmore">
                <Link to="/">learn more</Link>
                <ChevronRight className="rightArr" href="#/" size="20" />
              </li>
            </ul>
            <svg
              className="tech-triangle"
              xmlns="http://www.w3.org/2000/svg"
              width={276}
              height={42}
              viewBox="0 0 276 42"
            >
              <path
                fill="#FFF"
                fillRule="evenodd"
                stroke="#AAC7ED"
                d="M276 1L138 41 0 1"
              />
            </svg>
            <label htmlFor="post-4" className="read-more-trigger" />
          </div>
          <div className="tech-item">
            <figure className="hex-media">
              <img src={IoTSvg} alt="IoT" />
              <figcaption>
                <h3>
                  <Link to="/">
                    {t("src.pages.companyPage.technology.IoT.title")}
                  </Link>
                </h3>
              </figcaption>
            </figure>
            <input type="checkbox" className="read-more-state" id="post-5" />
            <ul className="item-list read-more-wrap">
              {t("src.pages.companyPage.technology.IoT.data").map(
                (content, index) => {
                  return <li key={index}>{content}</li>
                }
              )}
            </ul>
            <svg
              className="tech-triangle"
              xmlns="http://www.w3.org/2000/svg"
              width={276}
              height={42}
              viewBox="0 0 276 42"
            >
              <path
                fill="#FFF"
                fillRule="evenodd"
                stroke="#AAC7ED"
                d="M276 1L138 41 0 1"
              />
            </svg>
            <label htmlFor="post-5" className="read-more-trigger" />
          </div>
        </div>
      </section>
    </div>
  )
}

export default withTranslation()(Technology)
