import React from "react"
import { withTranslation } from "react-i18next"
import "./clientsServices.scss"
import AboutLogo from "../../../homepageInnos/about/aboutLogo.components"

const ClientsServices = props => {
  const { t } = props
  return (
    <div className="company-clients-services">
      <section id="" className=" cemetery">
        <div className="container-fluid container" style={{ opacity: 1 }}>
          <div
            className="cemetery__title"
            style={{ opacity: 1, marginBottom: "30px" }}
          >
            <h2>{t("src.pages.companyPage.ourClients")}</h2>
          </div>
          <AboutLogo />
        </div>
      </section>
    </div>
  )
}

export default withTranslation()(ClientsServices)
