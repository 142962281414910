import React from "react"
//import {Link} from "gatsby"
import Layout from '../components/layout/layout.higherComponent'
import Head from '../components/head'
import CompanyPage from '../components/Company/CompanyPage'

const Company =()=>{
  return(
      <Layout>
        <Head title="Company"/>
        <CompanyPage />
      </Layout>
  )
}

export default Company
