import React from "react"
import { withTranslation } from "react-i18next"
import { Link } from "gatsby"
// import { dataCompanyServiceCta } from '../../../../data/dataCompany'
import "./serviceCta.scss"

function ServiceCta(props) {
  const { t } = props
  return (
    <div className="servicecta">
      <section className="service-cta service-cta--about">
        <div className="service-cta__text-area service-cta__text-area--it-consulting">
          <p className="service-cta__white">
            {t("src.pages.companyPage.contact.content")}
          </p>
          <Link to="/company/contact" className="get-touch btn btn--about-news">
            {t("src.pages.companyPage.contact.talkToUs")}
          </Link>{" "}
        </div>
      </section>
    </div>
  )
}

export default withTranslation()(ServiceCta)
