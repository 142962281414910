import React from "react"
import { withTranslation } from "react-i18next"
// import { dataCompany } from '../../../../data/dataCompany'
import BgVideo from "../../../../assets/img/media/company/about/about-bg-video.mp4"
import BgPoster from "../../../../assets/img/media/company/about/bg-poster.jpg"
import "./companyPageTitle.scss"

const CompanyPageTitle = props => {
  const { t } = props
  return (
    <div className="company-page-title">
      <section className="company-header company-hero hero--full video-section">
        <div className="video-wrapper">
          <video
            className="video-bg"
            autoPlay
            loop
            muted
            playsInline
            poster={BgPoster}
          >
            <source src={BgVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="container about-header">
          <div className="content-header">
            <h1 className="title title--uppercase">
              <span className="title__preheader title__preheader--tag">
                {t("src.pages.companyPage.company")}
              </span>
              {t("src.pages.companyPage.title")}
            </h1>
            <p className="hero__lead">
              {t("src.pages.companyPage.descriptions")}
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default withTranslation()(CompanyPageTitle)
